import router from '../router'
import generalSettings from '../settings/generalSettings'
import {uiSettings} from '../settings/uiSettings'

import {store} from '../store/index';

export default {
    methods: {
        goto(path, paramsDesc) {
            router.push({
                name: path,
                params: paramsDesc,
            });
        },
        saveFiltersToStore(filters, route) {
            var filterData = {
                filters: filters,
                route: route
            };
            store.dispatch('filters/setFilter', filterData);
        },
        notNullOrEmptyField(field) {
            return field != undefined && field != "";
        },
        notNullOrEmptyObject(object) {
            return object != undefined && Object.keys(object).length > 0;
        },
        checkForUnauthorizedError(error) {
            if (error.response && error.response.status === 401) {
                this.$store.commit('login/endUserSession');
                this.goto('login', {session: 'false'});
            }
        },
        hasAccess(availableMenus, toMeniu) {
            if (availableMenus.includes(toMeniu)) {
                return true;
            }
            return false;
        },
        formatCSDate(date) {
            if (date != null) {
                var dateObj = new Date(date);
                var localDate = dateObj.toLocaleDateString(generalSettings.countryLocale);
                return localDate;
            }
            return date;
        },
        formatCSDateAndTime(date) {
            if (date != null && date != '') {
                var dateObj = new Date(date);
                var localDate = dateObj.toLocaleString(generalSettings.countryLocale);
                return localDate;
            }
            return date;
        },
        getProductName(code) {
            switch (code) {
                case "PS": {
                    return "Sport Bets";
                }
                case "Keno": {
                    return "Keno";
                }
                case "BSix": {
                    return "Ball Six";
                }
                case "Rlt": {
                    return "Roulette";
                }
                case "Lt": {
                    return "Lotto";
                }
            }
        },
        validateCNP(p_cnp) {
            var i = 0, year = 0, hashResult = 0, cnp = [], hashTable = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9];
            if (p_cnp.length !== 13) {return false;}
            for (i = 0; i < 13; i++) {
                cnp[i] = parseInt(p_cnp.charAt(i), 10);
                if (isNaN(cnp[i])) {return false;}
                if (i < 12) {hashResult = hashResult + (cnp[i] * hashTable[i]);}
            }
            hashResult = hashResult % 11;
            if (hashResult === 10) {hashResult = 1;}
            year = (cnp[1] * 10) + cnp[2];
            switch (cnp[0]) {
                case 1: case 2: {year += 1900;} break;
                case 3: case 4: {year += 1800;} break;
                case 5: case 6: {year += 2000;} break;
                case 7: case 8: case 9: {year += 2000; if (year > (parseInt(new Date().getYear(), 10) - 14)) {year -= 100;} } break;
                default: {return false;}
            }
            if (year < 1800 || year > 2099) {return false;}
            return (cnp[12] === hashResult);
        },
        formatStringDate(dateString = '') {
            // date 10-03-2018 12:44 -> 1003181244
            if (dateString === '') {
                return ''
            } else {
                return dateString.substring(8, 10) + dateString.substring(5, 7) + dateString.substring(2, 4) + dateString.substring(11, 13) + dateString.substring(14, 16)
            }
        },
        formatApiDate(date) {
            if (this.notNullOrEmptyField(date)) {
                let day = date.getDate();
                let month = date.getMonth() + 1;
                let yearString = date.getFullYear().toString().substr(2, 2);
                let hours = date.getHours();
                var minutes = date.getMinutes();
                let dayString = day > 9 ? day.toString() : "0" + day;
                let monthString = month > 9 ? month.toString() : "0" + month;
                let hoursString = hours > 9 ? hours.toString() : "0" + hours;
                let minutesString = minutes > 9 ? minutes.toString() : "0" + minutes;
                return `${dayString}${monthString}${yearString}${hoursString}${minutesString}`;
            }
            return "";
        },
        formatEnDate(stringDate) {
            var date = new Date(stringDate);
            let day = date.getDate();
            let dayString = day > 9 ? day.toString() : "0" + day;
            let month = date.getMonth() + 1;
            let monthString = month > 9 ? month.toString() : "0" + month;
            let year = date.getFullYear();

            return `${year}-${monthString}-${dayString}`;
        },
        formatEnDateAndTime(stringDate) {
            var date = new Date(stringDate);
            let day = date.getDate();
            let dayString = day > 9 ? day.toString() : "0" + day;
            let month = date.getMonth() + 1;
            let monthString = month > 9 ? month.toString() : "0" + month;
            let year = date.getFullYear();
            let hours = date.getHours();
            var minutes = date.getMinutes();
            let hoursString = hours > 9 ? hours.toString() : "0" + hours;
            let minutesString = minutes > 9 ? minutes.toString() : "0" + minutes;
            return `${year}-${monthString}-${dayString} ${hoursString}:${minutesString}`;
        },
        getTicketStatusColor(status) {
            switch (status) {
                case 1: {return 'success';}
                case 2: {return 'warning';}
                case 0: {return 'danger';}
                case 3: {return 'info';}
                case 4: {return 'help';}
                case 5: {return 'plain';}
                case -1: {return 'plain';}
            }
        },
        getTicketStatusName(status) {
            switch (status) {
                case 1: {return 'winner';}
                case 2: {return 'pending';}
                case 0: {return 'lost';}
                case 3: {return 'paid';}
                case 4: {return 'expired';}
                case 5: {return 'storno';}
                case -1: {return 'void';}

            }
        },
        isWinnerTicket(ticketStatus) {
            if (ticketStatus == 1 || ticketStatus == -1 || ticketStatus == 3) {
                return true;
            }
            return false;
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        getToastLifeSeconds() {
            return uiSettings.toastLifeSeconds;
        },
        getSearchDebounceSeconds() {
            return uiSettings.searchDebounceMiliSeconds;
        },
        getReportsStartYear() {
            return generalSettings.reportsStartYear;
        },
        decotLiveFixtureStatus(status)
        {
            switch (status) {
                case 1: {return 'Not Started Yet';}
                case 2: {return 'In Progress';}
                case 0: {return 'Not Set';}
                case 3: {return 'Finished';}
                case 4: {return 'Cancelled';}
                case 5: {return 'Postponed';}
                case 6: {return 'Interrupted';}
                case 7: {return 'Abandoned';}
                case 8: {return 'Lost coverage';}
                case 9: {return 'About to start';}

            }
        },

        //to remove
        //old bo 
        getActiveColor(active) {
            return active ? "success" : "grey";
        },
        getStatusBoolColor(status) {
            switch (status) {
                case true: {return 'success';}
                case false: {return 'danger';}
                default: {return 'warning';}
            }
        },
        getPaymentsStatusColor(status) {
            switch (status) {
                case "SUCCESS": {return 'green';}
                case "PENDING": {return 'warning';}
                case "FAILED": {return 'danger';}
                case "": {return 'info';}
            }
        },

        getBlockedColor(status) {
            switch (status) {
                case 1: {return 'danger';}
                case 2: {return 'warning';}
                case 0: {return 'success';}
            }
        },
        getTicketVirtualColor(fromWeb) {
            return fromWeb ? "ion ion-md-cloud-done" : "";
        },
        getTicketOnlineColor(fromWeb) {
            return fromWeb != "" && fromWeb != null ? "ion ion-md-cloud-done" : "";
        },
        getTicketLiveColor(isLive) {
            return isLive ? "entypo entypo-monitor" : "";
        },
        getTicketECColor(isLive) {
            return isLive ? "fa fa-hand-holding-usd" : "";
        },


        formatCSDateAndTimeNoMiliS(date) {
            return date.substring(0, date.indexOf('T')) + " " + date.substring(date.indexOf('T') + 1, date.indexOf('.'));
        },
        formatCurrency(amount) {
            return parseFloat(amount).toLocaleString(generalSettings.countryLocale, {minimumFractionDigits: 2});
        },
        formatCurrencyInt(amount) {
            return parseFloat(amount).toLocaleString(generalSettings.countryLocale);
        },
        getCurrentDate() {
            //test
            let crtDate = new Date();
            let year = crtDate.getFullYear();
            let month = crtDate.getMonth() + 1;
            if (month <= 9) {
                month = "0" + month;
            }
            let day = crtDate.getDate();
            if (day <= 9) {
                day = "0" + day;
            }
            let seconds = crtDate.getSeconds();
            if (seconds <= 9) {
                seconds = "0" + seconds;
            }
            let minutes = crtDate.getMinutes();
            if (minutes <= 9) {
                minutes = "0" + minutes;
            }
            let hours = crtDate.getHours();
            if (hours <= 9) {
                hours = "0" + hours;
            }
            return (
                day +
                "-" +
                month +
                "-" +
                year +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                seconds
            );
        },

        getRowIndex(crtPage, perPage, crtIndex) {
            return (crtPage - 1) * perPage + crtIndex + 1;
        },
        getColors() {
            const isLight = this.$appState.layoutMode === 'light';
            return {
                pinkColor: isLight ? '#EC407A' : '#F48FB1',
                purpleColor: isLight ? '#AB47BC' : '#CE93D8',
                deeppurpleColor: isLight ? '#7E57C2' : '#B39DDB',
                indigoColor: isLight ? '#5C6BC0' : '#9FA8DA',
                blueColor: isLight ? '#42A5F5' : '#90CAF9',
                lightblueColor: isLight ? '#29B6F6' : '#81D4FA',
                cyanColor: isLight ? '#00ACC1' : '#4DD0E1',
                tealColor: isLight ? '#26A69A' : '#80CBC4',
                greenColor: isLight ? '#66BB6A' : '#A5D6A7',
                lightgreenColor: isLight ? '#9CCC65' : '#C5E1A5',
                limeColor: isLight ? '#D4E157' : '#E6EE9C',
                yellowColor: isLight ? 'FFEE58' : '#FFF59D',
                amberColor: isLight ? '#FFCA28' : '#FFE082',
                orangeColor: isLight ? '#FFA726' : '#FFCC80',
                deeporangeColor: isLight ? '#FF7043' : '#FFAB91',
                brownColor: isLight ? '#8D6E63' : '#BCAAA4'
            }
        },

    }
}

