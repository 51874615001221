<template>
    <div class="layout-topbar p-shadow-4">
        <div class="layout-topbar-left">
            <a class="layout-topbar-logo">
                <img id="logo" src="logo.png" alt="ultima-layout" style="height: 1.25rem" />
            </a>

            <a class="layout-menu-button p-shadow-6 p-ripple" @click="onMenuButtonClick($event)" v-ripple>
                <i class="pi pi-chevron-right"></i>
            </a>
        </div>

        <div class="layout-topbar-right" :class="{ 'layout-topbar-mobile-active': mobileTopbarActive }">
            <div class="layout-topbar-actions-left">
                <MegaMenu :model="items" class="layout-megamenu"></MegaMenu>
            </div>

            <div class="layout-topbar-actions-right"></div>
        </div>
    </div>
</template>

<script>
export default {
    emits: ['menubutton-click', 'topbar-menubutton-click', 'topbaritem-click', 'rightpanel-button-click', 'topbar-mobileactive', 'search-click', 'search-toggle'],
    data() {
        return {
            searchText: '',
            items: [],
        };
    },
    props: {
        horizontal: {
            type: Boolean,
            default: false,
        },
        topbarMenuActive: {
            type: Boolean,
            default: false,
        },
        activeTopbarItem: String,
        mobileTopbarActive: Boolean,
        searchActive: Boolean,
    },
    methods: {
        onSearchContainerClick(event) {
            this.$emit('search-click', event);
        },
        changeSearchActive(event) {
            this.$emit('search-toggle', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menubutton-click', event);
        },
        onTopbarMenuButtonClick(event) {
            this.$emit('topbar-menubutton-click', event);
        },
        onTopbarItemClick(event, item) {
            if (item === 'search') {
                this.$emit('search-toggle', event);
            }

            this.$emit('topbaritem-click', { originalEvent: event, item: item });
        },
        onTopbarMobileButtonClick(event) {
            this.$emit('topbar-mobileactive', event);
        },
        onRightPanelButtonClick(event) {
            this.$emit('rightpanel-button-click', event);
        },
        onSearchKeydown(event) {
            if (event.keyCode == 13) {
                this.$emit('search-toggle', event);
            }
        },
        onEnter() {
            if (this.$refs.searchInput) {
                this.$refs.searchInput.$el.focus();
            }
        },
    },
    computed: {
        topbarItemsClass() {
            return [
                'topbar-items animated fadeInDown',
                {
                    'topbar-items-visible': this.topbarMenuActive,
                },
            ];
        },
        isRTL() {
            return this.$appState.RTL;
        },
    },
};
</script>
