<template>
    <div class="layout-footer p-d-flex p-ai-center p-p-4 p-shadow-2">
        <img id="footer-logo" :src="'logo.png'" alt="ultima-footer-logo" style="height: 3rem" />
    </div>
</template>

<script>
export default {
    name: 'AppFooter',
    props: {
        layoutMode: {
            type: String,
            default: 'light',
        },
    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
    },
};
</script>

<style scoped>
</style>