let uiSettings = {
    tableHeight: 400,
    tableRows: 20,
    toastLifeSeconds: 3000,
    searchDebounceMiliSeconds:400
}

export {
    uiSettings
}
