import {createRouter, createWebHashHistory} from 'vue-router';

const routes = [
    {
        path: '/',
        redirect: {name: 'dashboardGeneral'},
    },
    {
        path: '/dashboardGeneral',
        name: 'dashboardGeneral',
        exact: true,
        component: () => import('./pages/Dashboard/DashboardGeneral.vue'),
        meta: {
            breadcrumb: [{label: 'Dashboard'}],
            requiresAuth: true
        },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/contactus',
        name: 'contactus',
        component: () => import('./pages/ContactUs.vue')
    },
    //sports settings
    {
        path: '/sports',
        name: 'sports',
        exact: true,
        component: () => import('./pages/SportSettings/Sports.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Sports'}],
            requiresAuth: true
        },
    },
    {
        path: '/sports/edit/:id',
        name: 'sports/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditSport.vue'),
        meta: {
            breadcrumb: [{parent: 'Sports', label: 'Edit Sport'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/categories',
        name: 'categories',
        exact: true,
        component: () => import('./pages/SportSettings/Categories.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Categories'}],
            requiresAuth: true
        },
    },
    {
        path: '/categories/edit/:id',
        name: 'categories/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditCategory.vue'),
        meta: {
            breadcrumb: [{parent: 'Categories', label: 'Edit Category'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/tournaments',
        name: 'tournaments',
        exact: true,
        component: () => import('./pages/SportSettings/Tournaments.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Tournaments'}],
            requiresAuth: true
        },
    },
    {
        path: '/tournaments/edit/:id',
        name: 'tournaments/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditTournament.vue'),
        meta: {
            breadcrumb: [{parent: 'Tournaments', label: 'Edit Tournament'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/competitors',
        name: 'competitors',
        exact: true,
        component: () => import('./pages/SportSettings/Competitors.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Competitors'}],
            requiresAuth: true
        },
    },
    {
        path: '/competitors/edit/:id',
        name: 'competitors/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditCompetitor.vue'),
        meta: {
            breadcrumb: [{parent: 'Tournaments', label: 'Edit Competitor'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/popularLeagues',
        name: 'popularLeagues',
        exact: true,
        component: () => import('./pages/SportSettings/PopularLeagues.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Popular Leagues'}],
            requiresAuth: true
        },
    },
    {
        path: '/regularMarkets',
        name: 'regularMarkets',
        exact: true,
        component: () => import('./pages/SportSettings/Markets.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Markets'}],
            requiresAuth: true
        },
    },
    {
        path: '/regularMarkets/edit/:id',
        name: 'regularMarkets/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditMarket.vue'),
        meta: {
            breadcrumb: [{parent: 'markets', label: 'Edit Market'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    }, {
        path: '/specialMarkets',
        name: 'specialMarkets',
        exact: true,
        component: () => import('./pages/SportSettings/SpecialMarkets.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Special Markets'}],
            requiresAuth: true
        },
    },
    {
        path: '/specialMarkets/edit/:id',
        name: 'specialMarkets/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditSpecialMarket.vue'),
        meta: {
            breadcrumb: [{parent: 'markets', label: 'Edit Special Market'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    }
    , {
        path: '/displayMarkets',
        name: 'displayMarkets',
        exact: true,
        component: () => import('./pages/SportSettings/DisplayMarkets.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Display Markets'}],
            requiresAuth: true
        },
    },
    {
        path: '/displayMarkets/edit/:id',
        name: 'displayMarkets/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditDisplayMarket.vue'),
        meta: {
            breadcrumb: [{parent: 'markets', label: 'Edit Display Market'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    }, {
        path: '/marketGroups',
        name: 'marketGroups',
        exact: true,
        component: () => import('./pages/SportSettings/MarketGroups.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Market Groups'}],
            requiresAuth: true
        }
    },
    {
        path: '/marketGroups/edit/:id',
        name: 'marketGroups/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditMarketGroup.vue'),
        meta: {
            breadcrumb: [{parent: 'markets', label: 'Edit Market Group'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/marketTemplates',
        name: 'marketTemplates',
        exact: true,
        component: () => import('./pages/SportSettings/MarketTemplates.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Market Templates'}],
            requiresAuth: true
        }
    },
    {
        path: '/oddsTemplates/edit/:id',
        name: 'oddsTemplates/edit',
        exact: true,
        component: () => import('./pages/SportSettings/EditOddsTemplate.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Edit Odds Templates'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/oddsTemplates',
        name: 'oddsTemplates',
        exact: true,
        component: () => import('./pages/SportSettings/OddTemplates.vue'),
        meta: {
            breadcrumb: [{parent: 'SportSettings', label: 'Odds Templates'}],
            requiresAuth: true
        }
    },
    {
        path: '/lsportsSports',
        name: 'lsportsSports',
        exact: true,
        component: () => import('./pages/SportSettings/LSports/Sports.vue'),
        meta: {
            breadcrumb: [{parent: 'Lsports Live', label: 'Sports'}],
            requiresAuth: true
        }
    },
    {
        path: '/lsportsLocations',
        name: 'lsportsLocations',
        exact: true,
        component: () => import('./pages/SportSettings/LSports/Locations.vue'),
        meta: {
            breadcrumb: [{parent: 'Lsports Live', label: 'Locations'}],
            requiresAuth: true
        }
    },
    {
        path: '/lsportsLeagues',
        name: 'lsportsLeagues',
        exact: true,
        component: () => import('./pages/SportSettings/LSports/Leagues.vue'),
        meta: {
            breadcrumb: [{parent: 'Lsports Live', label: 'Leagues'}],
            requiresAuth: true
        }
    },
    //system menu
    {
        path: '/users',
        name: 'users',
        exact: true,
        component: () => import('./pages/System/AdminUsers.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Admin Users'}],
            requiresAuth: true
        }
    },
    {
        path: '/users/edit/:id',
        name: 'users/edit',
        exact: true,
        component: () => import('./pages/System/EditAdminUser.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Admin User'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/roles',
        name: 'roles',
        exact: true,
        component: () => import('./pages/System/Roles.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Roles'}],
            requiresAuth: true
        }
    },
    {
        path: '/roles/edit/:id',
        name: 'roles/edit',
        exact: true,
        component: () => import('./pages/System/EditRole.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Role'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/regions',
        name: 'regions',
        exact: true,
        component: () => import('./pages/System/Regions.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Regions'}],
            requiresAuth: true
        }
    },
    {
        path: '/regions/edit/:id',
        name: 'regions/edit',
        exact: true,
        component: () => import('./pages/System/EditRegion.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Role'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/agents',
        name: 'agents',
        exact: true,
        component: () => import('./pages/System/Agents.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Agents'}],
            requiresAuth: true
        }
    },
    {
        path: '/agents/edit/:id',
        name: 'agents/edit',
        exact: true,
        component: () => import('./pages/System/EditAgent.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Agent'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/shops',
        name: 'shops',
        exact: true,
        component: () => import('./pages/System/Shops.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Shops'}],
            requiresAuth: true
        }
    },
    {
        path: '/shops/edit/:id',
        name: 'shops/edit',
        exact: true,
        component: () => import('./pages/System/EditShop.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Shop'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/cashiers',
        name: 'cashiers',
        exact: true,
        component: () => import('./pages/System/Cashiers.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Cashiers'}],
            requiresAuth: true
        }
    },
    {
        path: '/cashiers/edit/:id',
        name: 'cashiers/edit',
        exact: true,
        component: () => import('./pages/System/EditCashier.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Cashier'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/installations',
        name: 'installations',
        exact: true,
        component: () => import('./pages/System/Installations.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Installations'}],
            requiresAuth: true
        }
    },
    {
        path: '/installations/edit/:id',
        name: 'installations/edit',
        exact: true,
        component: () => import('./pages/System/EditInstallation.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Installation'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/pendingInstallations',
        name: 'pendingInstallations',
        exact: true,
        component: () => import('./pages/System/PendingInstallations.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Pending Installations'}],
            requiresAuth: true
        }
    },
    {
        path: '/authorization',
        name: 'authorization',
        exact: true,
        component: () => import('./pages/System/Authorization.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Authorization'}],
            requiresAuth: true
        }
    },
    {
        path: '/ssbtStatus',
        name: 'ssbtStatus',
        exact: true,
        component: () => import('./pages/System/SSBTStatus.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'SSBTStatus'}],
            requiresAuth: true
        }
    },
    {
        path: '/devices',
        name: 'devices',
        exact: true,
        component: () => import('./pages/System/Devices.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Devices'}],
            requiresAuth: true
        }
    },
    {
        path: '/devices/edit/:id',
        name: 'devices/edit',
        exact: true,
        component: () => import('./pages/System/EditDevice.vue'),
        meta: {
            breadcrumb: [{parent: 'System', label: 'Edit Device'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/kenoResults',
        name: 'kenoResults',
        exact: true,
        component: () => import('./pages/Results/Keno.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Keno'}],
            requiresAuth: true
        }
    },
    {
        path: '/ballSixResults',
        name: 'ballSixResults',
        exact: true,
        component: () => import('./pages/Results/BallSix.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'BallSix'}],
            requiresAuth: true
        }
    },
    {
        path: '/rouletteResults',
        name: 'rouletteResults',
        exact: true,
        component: () => import('./pages/Results/Roulette.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Roulette'}],
            requiresAuth: true
        }
    },
    {
        path: '/lottoResults',
        name: 'lottoResults',
        exact: true,
        component: () => import('./pages/Results/Lotto.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Lotto'}],
            requiresAuth: true
        }
    },
    {
        path: '/addLottoResults/:id',
        name: 'addLottoResults',
        exact: true,
        component: () => import('./pages/Results/AddLottoResults.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Add Lotto results'}],
            requiresAuth: true
        }
    },
    {
        path: '/sportsResults',
        name: 'sportsResults',
        exact: true,
        component: () => import('./pages/Results/SportBets.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Sport Bets'}],
            requiresAuth: true
        }
    },
    {
        path: '/pendingResults',
        name: 'pendingResults',
        exact: true,
        component: () => import('./pages/Results/SportBetsPending.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Sport Bets Pending'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/liveResults',
        name: 'liveResults',
        exact: true,
        component: () => import('./pages/Results/LiveResults.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Live Results'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/eventResults',
        name: 'eventResults',
        exact: true,
        component: () => import('./pages/Results/EventResults.vue'),
        meta: {
            breadcrumb: [{parent: 'Results', label: 'Event Results'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/processEvent',
        name: 'processEvent',
        exact: true,
        component: () => import('./pages/OfferMonitoring/ProcessEvent.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Process Event'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/importOffer',
        name: 'importOffer',
        exact: true,
        component: () => import('./pages/OfferMonitoring/ImportSportFixtures.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Import Sport Fixtures'}],
            requiresAuth: true
        }
    },
    {
        path: '/currentOffer',
        name: 'currentOffer',
        exact: true,
        component: () => import('./pages/OfferMonitoring/SportOffer.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Current Offer'}],
            requiresAuth: true
        }
    },
    {
        path: '/liveOffer',
        name: 'liveOffer',
        exact: true,
        component: () => import('./pages/OfferMonitoring/LiveOffer.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Live Offer'}],
            requiresAuth: true
        }
    },
    {
        path: '/liveEventDetails/:id',
        name: 'liveEventDetails',
        exact: true,
        component: () => import('./pages/OfferMonitoring/LiveEventDetails.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Live Event Details'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/eventDetails',
        name: 'eventDetails',
        exact: true,
        component: () => import('./pages/OfferMonitoring/EventDetails.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Event Details'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/addSpecialMarket',
        name: 'addSpecialMarket',
        exact: true,
        component: () => import('./pages/OfferMonitoring/AddSpecialMarkets.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Add Special Market'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/publishLottoOffer',
        name: 'publishLottoOffer',
        exact: true,
        component: () => import('./pages/OfferMonitoring/ImportLottoFixtures.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Import Lotto Fixtures'}],
            requiresAuth: true
        },
    },
    {
        path: '/lottoOffer',
        name: 'lottoOffer',
        exact: true,
        component: () => import('./pages/OfferMonitoring/LottoOffer.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Lotto Offer'}],
            requiresAuth: true
        },
    },
    {
        path: '/topOffer',
        name: 'topOffer',
        exact: true,
        component: () => import('./pages/OfferMonitoring/OddsBoost.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Odds Boost'}],
            requiresAuth: true
        },
    },
    {
        path: '/createEvent',
        name: 'createEvent',
        exact: true,
        component: () => import('./pages/OfferMonitoring/CreateEvent.vue'),
        meta: {
            breadcrumb: [{parent: 'Offer Monitoring', label: 'Create Event'}],
            requiresAuth: true
        },
    },
    {
        path: '/sportBetsTickets',
        name: 'sportBetsTickets',
        exact: true,
        component: () => import('./pages/Bets/SportBets.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Sport Bets'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/lottoTickets',
        name: 'lottoTickets',
        exact: true,
        component: () => import('./pages/Bets/Lotto.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Lotto'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/kenoTickets',
        name: 'kenoTickets',
        exact: true,
        component: () => import('./pages/Bets/Keno.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Keno'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/ballsixTickets',
        name: 'ballsixTickets',
        exact: true,
        component: () => import('./pages/Bets/Lucky6.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Lucky6'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/rocketTickets',
        name: 'rocketTickets',
        exact: true,
        component: () => import('./pages/Bets/Rocket.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Rocket'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/rouletteTickets',
        name: 'rouletteTickets',
        exact: true,
        component: () => import('./pages/Bets/Roulette.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Roulette'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/cashOutVouchers',
        name: 'cashOutVouchers',
        exact: true,
        component: () => import('./pages/Bets/SsbtVouchers.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'SSBT Vouchers'}],
            requiresAuth: true
        },
    },
    {
        path: '/ticketsLogs',
        name: 'ticketsLogs',
        exact: true,
        component: () => import('./pages/Bets/TicketsLogs.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets', label: 'Tickets Logs'}],
            requiresAuth: true
        },
    },
    {
        path: '/settleBets',
        name: 'settleBets',
        exact: true,
        component: () => import('./pages/Bets/SettleBets.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets Operations', label: 'Settle Bets'}],
            requiresAuth: true
        },
    },
    {
        path: '/payBets',
        name: 'payBets',
        exact: true,
        component: () => import('./pages/Bets/PayBets.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets Operations', label: 'Pay Bets'}],
            requiresAuth: true
        },
    },
    {
        path: '/cancelBets',
        name: 'cancelBets',
        exact: true,
        component: () => import('./pages/Bets/CancelBets.vue'),
        meta: {
            breadcrumb: [{parent: 'Bets Operations', label: 'Cancel Bets'}],
            requiresAuth: true
        },
    },
    {
        path: '/changePassword',
        name: 'changePassword',
        exact: true,
        component: () => import('./pages/Account/ChangePassword.vue'),
        meta: {
            breadcrumb: [{parent: 'Account', label: 'Change Password'}],
            requiresAuth: true
        },
    },
    {
        path: '/cashRegister',
        name: 'cashRegister',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/CashRegister.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Cash Register'}],
            requiresAuth: true
        },
    },
    {
        path: '/onjn',
        name: 'onjn',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/OnjnStornoStats.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Onjn Report'}],
            requiresAuth: true
        },
    },
    {
        path: '/amlReport',
        name: 'amlReport',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/AMLReport.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'AML Report'}],
            requiresAuth: true
        },
    },
    {
        path: '/eodSold',
        name: 'eodSold',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/DailyBalance.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Daily Balance'}],
            requiresAuth: true
        },
    },
    {
        path: '/winsById',
        name: 'winsById',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/PlayersWins.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Players Wins'}],
            requiresAuth: true
        },
    },
    {
        path: '/dailyIncomes',
        name: 'dailyIncomes',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/DailyIncomes.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Daily Incomes'}],
            requiresAuth: true
        },
    },
    {
        path: '/monthlyIncomes',
        name: 'monthlyIncomes',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/MonthlyIncomes.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Monthly Incomes'}],
            requiresAuth: true
        },
    },
    {
        path: '/cashRequirement',
        name: 'cashRequirement',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/CashRequirement.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Cash Requirement'}],
            requiresAuth: true
        },
    },
    {
        path: '/shopCD',
        name: 'shopCD',
        exact: true,
        component: () => import('./pages/Reporting/Accounting/ShopCreditDebit.vue'),
        meta: {
            breadcrumb: [{parent: 'Accounting Reports', label: 'Shop Credit & Debit'}],
            requiresAuth: true
        },
    },
    {
        path: '/profitLoss',
        name: 'profitLoss',
        exact: true,
        component: () => import('./pages/Reporting/Management/ProfitLoss.vue'),
        meta: {
            breadcrumb: [{parent: 'Management Reports', label: 'Profit Loss'}],
            requiresAuth: true
        },
    },
    {
        path: '/liveReport',
        name: 'liveReport',
        exact: true,
        component: () => import('./pages/Reporting/Management/LiveReport.vue'),
        meta: {
            breadcrumb: [{parent: 'Management Reports', label: 'Live Betting Incomes'}],
            requiresAuth: true
        },
    },
    {
        path: '/paidTickets',
        name: 'paidTickets',
        exact: true,
        component: () => import('./pages/Reporting/Management/PaidTickets.vue'),
        meta: {
            breadcrumb: [{parent: 'Management Reports', label: 'Paid Tickets'}],
            requiresAuth: true
        },
    },
    {
        path: '/terminalsData',
        name: 'terminalsData',
        exact: true,
        component: () => import('./pages/Reporting/Management/TerminalsData.vue'),
        meta: {
            breadcrumb: [{parent: 'Management Reports', label: 'SSBT Incomes'}],
            requiresAuth: true
        },
    },
    {
        path: '/incomesAndOutcomes',
        name: 'incomesAndOutcomes',
        exact: true,
        component: () => import('./pages/Reporting/Management/IncomesAndOutcomes.vue'),
        meta: {
            breadcrumb: [{parent: 'Management Reports', label: 'Incomes And Outcomes'}],
            requiresAuth: true
        },
    },
    {
        path: '/messages',
        name: 'messages',
        exact: true,
        component: () => import('./pages/Reporting/Shops/Messages.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Messages'}],
            requiresAuth: true
        },
    },
    {
        path: '/collaborationPlan',
        name: 'collaborationPlan',
        exact: true,
        component: () => import('./pages/Reporting/Shops/CollaborationPlans.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Collaboration Plans'}],
            requiresAuth: true
        },
    },
    {
        path: '/collaborationPlan/add',
        name: 'collaborationPlan/add',
        exact: true,
        component: () => import('./pages/Reporting/Shops/AddCollaborationPlan.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Add Collaboration Plan'}],
            requiresAuth: true
        },
        props: (route) => ({
            ...route.params
        })
    },
    {
        path: '/collaborationPlanHistory',
        name: 'collaborationPlanHistory',
        exact: true,
        component: () => import('./pages/Reporting/Shops/CollaborationPlansHistory.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Collaboration Plans History'}],
            requiresAuth: true
        },
    },
    {
        path: '/decont',
        name: 'decont',
        exact: true,
        component: () => import('./pages/Reporting/Shops/Decont.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Monthly Statement'}],
            requiresAuth: true
        },
    },
    {
        path: '/shopExpenses',
        name: 'shopExpenses',
        exact: true,
        component: () => import('./pages/Reporting/Shops/ShopsExpenses.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Shops Expenses'}],
            requiresAuth: true
        },
    },
    {
        path: '/riskManagement',
        name: 'riskManagement',
        exact: true,
        component: () => import('./pages/Reporting/RiskManagement.vue'),
        meta: {
            breadcrumb: [{parent: 'Reporting', label: 'Risk Management'}],
            requiresAuth: true
        },
    },
    {
        path: '/shopFinancialHistory',
        name: 'shopFinancialHistory',
        exact: true,
        component: () => import('./pages/Reporting/Shops/ShopFinancialHistory.vue'),
        meta: {
            breadcrumb: [{parent: 'Shops', label: 'Shops Financial History'}],
            requiresAuth: true
        },
    },
    {
        path: '/onlineBets',
        name: 'onlineBets',
        exact: true,
        component: () => import('./pages/Online/Bets.vue'),
        meta: {
            breadcrumb: [{parent: 'Online', label: 'Online Bets'}],
            requiresAuth: true
        },
    },
    /*  {
          path: '/favorites/dashboardanalytics',
          name: 'dashboardanalytics',
          exact: true,
          component: () => import('./components/DashboardAnalytics.vue'),
          meta: {
              breadcrumb: [{label: 'Dashboard Analytics'}],
          },
      },
      {
          path: '/formlayout',
          name: 'formlayout',
          component: () => import('./components/FormLayoutDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Form Layout'}],
          },
      },
      {
          path: '/invalidstate',
          name: 'invalidstate',
          component: () => import('./components/InvalidStateDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Invalid State'}],
          },
      },
      {
          path: '/input',
          name: 'input',
          component: () => import('./components/InputDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Input'}],
          },
      },
      {
          path: '/floatlabel',
          name: 'floatlabel',
          component: () => import('./components/FloatLabelDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Float Label'}],
          },
      },
      {
          path: '/button',
          name: 'button',
          component: () => import('./components/ButtonDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Button'}],
          },
      },
      {
          path: '/table',
          name: 'table',
          component: () => import('./components/TableDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Table'}],
          },
      },
      {
          path: '/list',
          name: 'list',
          component: () => import('./components/ListDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'List'}],
          },
      },
      {
          path: '/timeline',
          name: 'timeline',
          component: () => import('./pages/TimelineDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Timeline'}],
          },
      },
      {
          path: '/tree',
          name: 'tree',
          component: () => import('./components/TreeDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Tree'}],
          },
      },
      {
          path: '/panel',
          name: 'panel',
          component: () => import('./components/PanelsDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Panel'}],
          },
      },
      {
          path: '/overlay',
          name: 'overlay',
          component: () => import('./components/OverlaysDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Overlay'}],
          },
      },
      {
          path: '/media',
          name: 'media',
          component: () => import('./components/MediaDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Media'}],
          },
      },
      {
          path: '/menus',
          component: () => import('./components/MenusDemo.vue'),
          children: [
              {
                  path: '',
                  component: () => import('./components/menu/PersonalDemo.vue'),
                  meta: {
                      breadcrumb: [{parent: 'UI Kit', label: 'Menu'}],
                  },
              },
              {
                  path: '/menus/seat',
                  component: () => import('./components/menu/SeatDemo.vue'),
                  meta: {
                      breadcrumb: [{parent: 'UI Kit', label: 'Menu'}],
                  },
              },
              {
                  path: '/menus/payment',
                  component: () => import('./components/menu/PaymentDemo.vue'),
                  meta: {
                      breadcrumb: [{parent: 'UI Kit', label: 'Menu'}],
                  },
              },
              {
                  path: '/menus/confirmation',
                  component: () => import('./components/menu/ConfirmationDemo.vue'),
                  meta: {
                      breadcrumb: [{parent: 'UI Kit', label: 'Menu'}],
                  },
              },
          ],
      },
      {
          path: '/messages',
          name: 'messages',
          component: () => import('./components/MessagesDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Messages'}],
          },
      },
      {
          path: '/file',
          name: 'file',
          component: () => import('./components/FileDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'File'}],
          },
      },
      {
          path: '/chart',
          name: 'chart',
          component: () => import('./components/ChartsDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Charts'}],
          },
      },
      {
          path: '/misc',
          name: 'misc',
          component: () => import('./components/MiscDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'UI Kit', label: 'Misc'}],
          },
      },
      {
          path: '/icons',
          name: 'icons',
          component: () => import('./utilities/Icons.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Icons'}],
          },
      },
      {
          path: '/widgets',
          name: 'widgets',
          component: () => import('./utilities/Widgets.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Widgets'}],
          },
      },
      {
          path: '/grid',
          name: 'grid',
          component: () => import('./utilities/GridDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Grid System'}],
          },
      },
      {
          path: '/spacing',
          name: 'spacing',
          component: () => import('./utilities/SpacingDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Spacing'}],
          },
      },
      {
          path: '/elevation',
          name: 'elevation',
          component: () => import('./utilities/ElevationDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Elevation'}],
          },
      },
      {
          path: '/typography',
          name: 'typography',
          component: () => import('./utilities/Typography.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Typography'}],
          },
      },
      {
          path: '/display',
          name: 'display',
          component: () => import('./utilities/DisplayDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Display'}],
          },
      },
      {
          path: '/flexbox',
          name: 'flexbox',
          component: () => import('./utilities/FlexBoxDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Flexbox'}],
          },
      },
      {
          path: '/text',
          name: 'text',
          component: () => import('./utilities/TextDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Utilities', label: 'Text'}],
          },
      },
      {
          path: '/crud',
          name: 'crud',
          component: () => import('./pages/CrudDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Crud'}],
          },
      },
      {
          path: '/calendar',
          name: 'calendar',
          component: () => import('./pages/CalendarDemo.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Calendar'}],
          },
      },
      {
          path: '/invoice',
          name: 'invoice',
          component: () => import('./pages/Invoice.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Invoice'}],
          },
      },
      {
          path: '/help',
          name: 'help',
          component: () => import('./pages/Help.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Help'}],
          },
      },
      {
          path: '/empty',
          name: 'empty',
          component: () => import('./components/EmptyPage.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Empty Page'}],
          },
      },
      {
          path: '/documentation',
          name: 'documentation',
          component: () => import('./components/Documentation.vue'),
          meta: {
              breadcrumb: [{parent: 'Pages', label: 'Documentation'}],
          },
      },
     */
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;