import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl,
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    login(body) {
        return this.Api.post('/users/loginUltima', body)
    }
    //system menu 
    getUsers() {
        return this.Api.get('/users/getAllUsers')
    }
    getUser(userId) {
        return this.Api.get('/users/getUser/' + userId)
    }
    saveUser(userIdDetails) {
        return this.Api.post('/users/saveUser', userIdDetails)
    }
    resetUserPassword(userId) {
        return this.Api.get('/users/resetUserPassword/' + userId)
    }
    changeDefaultPassword(body) {
        return this.Api.post('/users/changeDefaultPassword/', body)
    }
    changeUserPassword(body) {
        return this.Api.post('/users/updateUserPassword/', body)
    }
    getRoles() {
        return this.Api.get('/users/getAllRoles')
    }
    getRole(roleId) {
        return this.Api.get('/users/getRole/' + roleId)
    }
    saveRole(roleIdDetails) {
        return this.Api.post('/users/saveRole', roleIdDetails)
    }
    getUsersForRole(roleId) {
        return this.Api.get('/users/getUsersForRole/' + roleId)
    }
    getMainMenuRoleAccess(roleName) {
        return this.Api.get('/users/getMainMenuRoleAccessUltima/' + roleName)
    }
    saveMainMenuRoleAccess(roleIdDetails) {
        return this.Api.post('/users/saveMainMenuRoleAccessUltima', roleIdDetails)
    }
    getBUnits(params) {
        return this.Api.get('/bettingOffice/getAllFranchisesPag', {params})
    }
    getBUnitsDdl() {
        return this.Api.get('/bettingOffice/getAllFranchises')
    }
    getBUnitDetails(bUnitId) {
        return this.Api.get('/bettingOffice/getFranchise/' + bUnitId)
    }
    saveBUnit(bUnitDetails) {
        return this.Api.post('/bettingOffice/saveFranchise', bUnitDetails)
    }
    deleteBUnit(bUnitDetails) {
        return this.Api.post('/bettingOffice/deleteFranchise', bUnitDetails)
    }
    getBUnitShops(bUnitId) {
        return this.Api.get(
            '/bettingOffice/getBettingOfficesForFranchise/' + bUnitId
        )
    }
    getRegions(params) {
        return this.Api.get('/bettingOffice/getAllRegionsPag', {params})
    }
    getRegionsDdl() {
        return this.Api.get('/bettingOffice/getAllRegions')
    }
    getRegionDetails(regionId) {
        return this.Api.get('/bettingOffice/getRegion/' + regionId)
    }
    saveRegion(regionId) {
        return this.Api.post('/bettingOffice/saveRegion', regionId)
    }
    getRegionShops(regionId) {
        return this.Api.get(
            '/bettingOffice/getBettingOfficesForRegion/' + regionId
        )
    }
    getShops() {
        return this.Api.get('/bettingOffice/getUserShops')
    }
    getShopsList(shopsIds){
        var shopsids = {IdsList : shopsIds};
        return this.Api.post('/bettingOffice/getBettingOfficeList',shopsids)
    }
    getUserFranchises() {
        return this.Api.get('/bettingOffice/getUserFranchises')
    }
    getShopsDetails(params) {
        return this.Api.get('/bettingOffice/getAllBettingOffices', {params})
    }
    getShopDetails(shopId) {
        return this.Api.get('/bettingOffice/getBettingOffice/' + shopId)
    }
    saveShop(shopDetails) {
        return this.Api.post('/bettingOffice/saveBettingOffice', shopDetails)
    }
    deleteShop(shopDetails) {
        return this.Api.post('/bettingOffice/deleteBettingOffice', shopDetails)
    }
    getCashiers(params) {
        return this.Api.get('/bettingOffice/getAllCashierUsers', {params})
    }
    getCashierDetails(userId) {
        return this.Api.get('/bettingOffice/getCashierUser/' + userId)
    }
    saveCashier(cashierDetails) {
        return this.Api.post('/bettingOffice/saveCashierUser', cashierDetails)
    }
    resetCashierPassword(cashierId) {
        return this.Api.get('/bettingOffice/resetCashierPassword/' + cashierId)
    }
    isUsernameAvailable(body) {
        return this.Api.post('/users/isUsernameAvailable', body)
    }
    isCashierUsernameAvailable(body) {
        return this.Api.post('/bettingOffice/isUsernameAvailable', body)
    }
    isRoleNameAvailable(roleName) {
        return this.Api.get('/users/isRoleNameAvailable/' + roleName)
    }

    getTerminalPendingRequest() {
        return this.Api.get('/terminal/getTerminalPendingRequest')
    }
    getCollaborationPlans(filter = 'all') {
        return this.Api.get('/bettingOffice/getCollaborationPlans/' + filter)
    }
    getCollaborationPlan(planId) {
        return this.Api.get('/bettingOffice/getCollaborationPlan/' + planId)
    }
    saveCollaborationPlan(planDetails) {
        return this.Api.post('/bettingOffice/saveCollaborationPlan', planDetails)
    }
    /* getDevices() {
         return this.Api.get('/devices/getActiveDevices');
     }*/
    saveDevice(deviceObj) {
        return this.Api.post('/devices/saveDevice', deviceObj);
    }
    deleteDevice(deviceId) {
        return this.Api.get('/devices/deleteDevice/' + deviceId);
    }
    resetDevice(deviceId) {
        return this.Api.get('/devices/resetDevice/' + deviceId);
    }
    getDevices(params) {
        return this.Api.get('/devices/getDevices', {params});
    }
    getPendingActivationRequests() {
        return this.Api.get('/terminal/getTerminalPendingRequest');
    }
    authorizeTerminal(terminalId) {
        return this.Api.get('terminal/authorize/' + terminalId);
    }
    getTerminals(params) {
        return this.Api.get('/terminal/getTerminalsPag', {params});
    }
    getTerminal(terminalId) {
        return this.Api.get('/terminal/getTerminal/' + terminalId);
    }
    saveTerminal(terminal) {
        var terminalUpdateObj = {
            Id: terminal._id,
            ShopId: terminal.shopId,
            IsActive: terminal.active
        }
        return this.Api.post('/terminal/saveTerminal', terminalUpdateObj);
    }
    deleteTerminal(terminalId) {
        return this.Api.get('/terminal/deleteTerminal/' + terminalId);
    }
    getPendingDevices() {
        return this.Api.get('/devices/getPendingDevices');
    }
    getVouchers(params) {
        return this.Api.get('/bettingOffice/getVouchers', {params});
    }
    getPendingVouchers(startDate, endDate, shopid) {
        return this.Api.get('/bettingOffice/getPendingVouchers' + '/' + startDate + '/' + endDate + '/' + shopid);
    }
    getAllUsers() {
        return this.Api.get('/settings/getAllUsers');
    }
    addShopCD(shopId, amount, tranType, comments) {
        var transactionObj = {
          TransactionType: tranType.id,
          Amount: amount,
          WithCash: true,
          ShopId: shopId,
          Comments: comments,
          IsCredit: tranType.id == "SHOP_CREDIT" ? true : false
        };
        return this.Api.post('/transactions/manualTransaction', transactionObj);
      }
    addShopExpense(shopId, shopCode, amount, date, comments) {
        var expenseDetails = {
          Amount: amount,
          ShopId: shopId,
          ShopCode: shopCode,
          Details: comments,
          Date: date
        };
        return this.Api.post('/bettingOffice/addShopExpense', expenseDetails);
      }
    getShopExpenses (shopId,startDate, endDate) {
        return this.Api.get('/bettingOffice/getShopExpenses/' + startDate + '/' + endDate + '/' + shopId)
    }
    getBlockedPlayers () {
        return this.Api.get('/players/getBlockedPlayers')
      }
    addBlockedPlayer (player) {
        return this.Api.post('/players/addBlockedPlayer', player)
      }
    removeBlokedPlayer (cardId) {
        return this.Api.get('/players/removeBlockedPlayer/' + cardId)
      }
    reactivateVoucher(voucherSerial){
        return this.Api.get('/tickets/reactivateVoucher/' + voucherSerial)

    }
    updateVoucher (voucherSerial, amount, reason) {
        var request = {
          VoucherSerial: voucherSerial,
          NewAmount: amount,
          Reason: reason,
        }
        return this.Api.post('/tickets/updateVoucher', request)
      }
      getTicketsLogs(params) {
        return this.Api.get('/bettingOffice/getTicketsLogs', {params});
    }
    getShopCollaborationPlans(shopId)
    {
        return this.Api.get('/bettingOffice/getCollaborationPlansForShop/'+shopId);        
    }
    saveCollaborationPlansHistory(plansToUpdate)
    {
        return this.Api.post('/bettingOffice/saveCollaborationPlanHistory',plansToUpdate);
    }

      

}

export {
    BoApi
}
