let settings = {
    TicketStatuses: [
        {name: 'All', value: 100},
        {name: 'Winner', value: 1},
        {name: 'Lost', value: 0},
        {name: 'Pending', value: 2},
        {name: 'Draw', value: -1},
        {name: 'Cancelled', value: 5},
        {name: 'Paid', value: 3},
        {name: 'Expired', value: 4}
    ],
    VouchersStatuses: [
        {name: 'All', value: 100},
        {name: 'Pending', value: 2},
        {name: 'Paid', value: 3}
    ],
    ticketType: {
        keno: "40",
        ballSix: "30",
        sportBets: "10",
        roulette: "50",
        cashOutVoucher: "99",
        lotto: "20",
        onlineSB: "19",
        rocket:"59"
    },
    gameType: {
        keno: "https://lgames.ibetplatform.com/virtualsweb/keno",
        ballsix: "https://lgames.ibetplatform.com/virtualsweb/ballsix",
        kenogame: "http://pow-ken.syncrogames.com",
        magnumsix: "https://lgames.ibetplatform.com/virtualsweb/ballsix2",
        devicename: "http://devicename.logicore.ro/devices.html?name=",
        lotto: "http://lottoview.powerbet.ro/",
        live:"https://livetv.powerbet.ro/",
        live2:"https://livetv.powerbet.ro/?monitor=2"
    },
    currency: " ",
    countryLocale: "ro-Ro",
    paymentsProviders: ["All", "ORANGE"],
    paymentsType: ["CREDIT", "DEBIT", "All"],
    paymentsStatuses: ["All", "SUCCESS", "FAILED", "PENDING"],
    paymentsConfirmedStatuses: ["All", "SUCCESS", "FAILED"],
    useCNPValidation: true,
    productChannels: {
        all: "ALL",
        retail: "RETAIL",
        terminal: "TERMINAL"
    },
    instalattionTypes: {
        all: "ALL",
        cashier: "CASHIER",
        terminal: "SSBT"
    },
    products: {
        sportsBetting: "SPORTSBETTING",
        keno: "KENO",
        ballsix: "BALLSIX",
        nsoft: "NSOFT",
        terminal: "TERMINAL",
        shopCD: "SHOPCD",
        all: "ALL",
        lotto: "LOTTO",
        roulette: "ROULETTE",
        rocket:"ROCKET",
        live:"LIVE"
    },
    thirdParties: {
        nsoft: "NSOFT"
    },
    reportsStartYear: 2019,
    blockedProductsList: [
        {name: 'PS', isBlocked: false},
        {name: 'Keno', isBlocked: false},
        {name: 'BSix', isBlocked: false},
        {name: 'Rlt', isBlocked: false},
        {name: 'Lt', isBlocked: false},
    ],

}

export default settings
