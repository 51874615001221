<template>
    <Login v-if="$route.path === '/login'" />
    <Error v-else-if="$route.path === '/error'" />
    <Access v-else-if="$route.path === '/access'" />
    <NotFound v-else-if="$route.path === '/notfound'" />
    <Landing v-else-if="$route.path === '/landing'" />
    <ContactUs v-else-if="$route.path === '/contactus'" />
    <App :topbarTheme="topbarTheme" :layoutMode="layoutMode" :menuTheme="menuTheme" @menu-theme="onMenuTheme" @topbar-theme="onTopbarThemeChange" @layout-mode-change="onLayoutChange" v-else />
</template>

<script>
import App from './App.vue';
import Error from './pages/Error';
import Access from './pages/Access';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Landing from './pages/Landing';
import ContactUs from './pages/ContactUs';

export default {
    data() {
        return {
            theme: 'blue',
            layoutMode: 'light',
            topbarTheme: 'blue',
            menuTheme: 'light',
        };
    },
    methods: {
        onLayoutChange(layout) {
            this.layoutMode = layout;
            this.$appState.layoutMode = layout;
            this.menuTheme = layout;

            const logo = document.getElementById('logo');
            logo.src = 'logo/png';

            if (layout === 'dark') {
                this.topbarTheme = 'dark';
            } else {
                this.topbarTheme = 'blue';
            }
        },
        onTopbarThemeChange(theme) {
            this.topbarTheme = theme.name;

            const themeName = theme.name;
            const logo = document.getElementById('logo');

            if (themeName == 'white' || themeName == 'yellow' || themeName == 'amber' || themeName == 'orange' || themeName == 'lime') {
                logo.src = 'logo.png';
            } else {
                logo.src = 'logo.png';
            }
        },
        onMenuTheme(menuTheme) {
            this.menuTheme = menuTheme.name;
        },
    },
    components: {
        App: App,
        Error: Error,
        Access: Access,
        Login: Login,
        NotFound: NotFound,
        Landing: Landing,
        ContactUs: ContactUs,
    },
};
</script>

<style scoped>
</style>
