<template>
    <div class="pages-body login-page p-d-flex p-flex-column">
        <div class="topbar p-p-3 p-d-flex p-jc-between p-flex-row p-ai-center">
            <div class="topbar-left p-ml-3 p-d-flex" routerLink="/">
                <div class="logo">
                    <img src="logo.png" alt="" />
                </div>
            </div>
            <div class="topbar-right p-mr-3 p-d-flex"></div>
        </div>

        <div class="p-as-center p-mt-auto p-mb-auto" v-if="isLogin">
            <div class="pages-panel card p-d-flex p-flex-column">
                <div class="pages-header p-px-3 p-py-1">
                    <h2>{{ $t('login.title') }}</h2>
                </div>

                <h4>{{ $t('login.welcome') }}</h4>

                <div class="pages-detail p-mb-6 p-px-6">{{ $t('login.message') }}</div>

                <div class="input-panel p-d-flex p-flex-column p-px-3">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-envelope"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText @keyup.enter="login()" v-model="user.username" type="text" id="username" />
                            <label for="email">{{ $t('login.username') }}</label>
                        </span>
                    </div>

                    <div class="p-inputgroup p-mt-3 p-mb-6">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText @keyup.enter="login()" v-model="user.password" type="password" id="password" />
                            <label for="password">{{ $t('login.password') }}</label>
                        </span>
                    </div>
                </div>
                <Button type="submit" class="login-button p-mb-6 p-px-3" @click="login()" :label="$t('login.login')"></Button>
                <transition-group name="p-message" tag="div" v-show="showError">
                    <Message :key="errorKeyCount" :severity="'error'">{{ errorMessage }}</Message>
                </transition-group>
            </div>
        </div>
        <div v-else class="p-as-center p-mt-auto p-mb-auto">
            <div class="pages-panel card p-d-flex p-flex-column">
                <div class="pages-header p-px-3 p-py-1">
                    <h2>{{ $t('login.changeDefaultPassTitle') }}</h2>
                </div>

                <h4>{{ $t('login.welcome') }}</h4>

                <div class="pages-detail p-mb-6 p-px-6">{{ $t('login.messageChangePass') }}</div>

                <div class="input-panel p-d-flex p-flex-column p-px-3">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-envelope"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText @keyup.enter="setNewPassword()" v-model="user.newPassword" type="password" id="username" />
                            <label for="email">{{ $t('login.newPassword') }}</label>
                        </span>
                    </div>

                    <div class="p-inputgroup p-mt-3 p-mb-6">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <InputText @keyup.enter="setNewPassword()" v-model="user.retypePassword" type="password" id="password" />
                            <label for="password">{{ $t('login.retypeNewPassword') }}</label>
                        </span>
                    </div>
                </div>
                <Button type="submit" class="login-button p-mb-6 p-px-3" @click="setNewPassword()" :label="$t('login.setPasssword')"></Button>
                <transition-group name="p-message" tag="div" v-show="showError">
                    <Message :key="errorKeyCount" :severity="'error'">{{ errorMessage }}</Message>
                </transition-group>
            </div>
        </div>
    </div>
</template>

<script>
import { BoApi } from '../service/BoApi';

export default {
    data() {
        return {
            passwordErrors: [],
            user: {
                username: '',
                password: '',
            },
            failedLogin: false,
            isLogin: true,
            BoApi: new BoApi(),
            showError: false,
            errorKeyCount: 0,
            errorMessage: '',
        };
    },
    methods: {
        login() {
            if (this.notNullOrEmptyField(this.user.username) && this.notNullOrEmptyField(this.user.password)) {
                this.failedLogin = false;
                this.$store
                    .dispatch('user/loginUser', this.user)
                    .then((response) => {
                        this.$store.dispatch('filters/clearAll');
                        if (response.passwordChangeNeeded === true) {
                            this.isLogin = false;
                        } else {
                            var firstMenu = this.$store.getters['user/firstAvailableMenu'];
                            if (firstMenu !== '') {
                                this.$router.push({ name: firstMenu });
                            }
                        }
                    })
                    .catch(() => {
                        this.showError = true;
                        this.errorMessage = this.$t('login.credentialsError');
                        this.errorKeyCount++;
                    });
            } else {
                this.errorMessage = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.errorKeyCount++;
            }
        },
        setNewPassword() {
            this.showError = false;
            if (this.notNullOrEmptyField(this.user.newPassword) && this.notNullOrEmptyField(this.user.retypePassword)) {
                if (this.user.newPassword !== this.user.retypePassword) {
                    this.errorMessage = this.$t('login.passwordMatch');
                    this.showError = true;
                    this.errorKeyCount++;
                } else {
                    let loginUser = {
                        username: this.user.username,
                        password: this.user.newPassword,
                    };
                    this.BoApi.changeDefaultPassword(loginUser)
                        .then(() => {
                            this.user.password = this.user.newPassword;
                            this.login();
                        })
                        .catch(() => {
                            this.errorMessage = this.$t('alerts.generalError');
                            this.showError = true;
                            this.errorKeyCount++;
                        });
                }
            } else {
                this.errorMessage = this.$t('forms.mandatoryFieldsMessage');
                this.showError = true;
                this.errorKeyCount++;
            }
        },
    },
};
</script>