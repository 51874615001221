let settings = {
    development: {
        betradarApiUrl: 'https://btrd-lgames.ibetplatform.com/api',
        sportsApiUrl: 'https://lgames.ibetplatform.com/sportsofferapi/api',
        boApiUrl: 'https://lgames.ibetplatform.com/backofficeapi/api',
        lottoApiUrl: 'https://lgames.ibetplatform.com/lottoapi/api',
        printTemplates: 'https://lgames.ibetplatform.com/print',
        everymatrixApiUrl: 'http://em.api-core.com/api',
        lsportsClientApiUrl:'https://lgames.ibetplatform.com/lsportsclientapi/api',
        lsportsProviderApiUrl:'https://lgames.ibetplatform.com/lsportsapi/api',
        terminalApi:'https://lgames.ibetplatform.com/terminalapi/api'
    },
    production: {
        betradarApiUrl: 'https://btrd-lgames.ibetplatform.com/api',
        sportsApiUrl: 'https://lgames.ibetplatform.com/sportsofferapi/api',
        boApiUrl: 'https://lgames.ibetplatform.com/backofficeapi/api',
        lottoApiUrl: 'https://lgames.ibetplatform.com/lottoapi/api',
        printTemplates: 'https://lgames.ibetplatform.com/print',
        everymatrixApiUrl: 'http://em.api-core.com/api',
        lsportsClientApiUrl:'https://lgames.ibetplatform.com/lsportsclientapi/api',
        lsportsProviderApiUrl:'https://lgames.ibetplatform.com/lsportsapi/api',
        terminalApi:'https://lgames.ibetplatform.com/terminalapi/api'

    }
}

export default settings
