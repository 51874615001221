import {
    BoApi
} from '../../service/BoApi'

export default {
    namespaced: true,
    state: {
        username: '',
        role: '',
        menus: [],
        sessionActive: false,
        availableMenus: [],
        userToken: '',
        userRealName: ''
    },
    mutations: {
        startUserSession(state, userData) {
            state.username = userData['User']
            state.role = userData['Role']
            state.menus = userData['Menus']
            state.availableMenus = userData['availableMenus']
            state.sessionActive = true
            state.userToken = userData.accessToken
            state.userRealName = userData.realName
        },
        endUserSession(state) {
            state.username = ''
            state.role = ''
            state.menus = []
            state.sessionActive = false
            state.availableMenus = []
            state.userToken = ''
            state.userRealName = ''
        }
    },
    actions: {
        loginUser(context, loginData) {
            return new Promise((resolve, reject) => {
                if (loginData['username'] !== '' && loginData['password'] !== '') {
                    let boApi = new BoApi()
                    let loginUser = {
                        username: loginData['username'],
                        password: loginData['password']
                    }
                    boApi.login(loginUser).then(function (response) {
                        if (response.data.passwordChangeNeeded) {
                            let userData = {
                                'accessToken': response.data.accessToken,
                                'passwordChangeNeeded': response.data.passwordChangeNeeded,
                                'User': response.data.username
                            }
                            resolve(userData)
                        }
                        let userData = {
                            'User': response.data.username,
                            'Menus': response.data.menu.labels,
                            'availableMenus': [],
                            'realName': response.data.realName,
                            'accessToken': response.data.accessToken,
                            'passwordChangeNeeded': response.data.passwordChangeNeeded
                        };
                        // add changePassword to available routes
                        response.data.menu.labels.forEach(label => {
                            label.menus.forEach(menu => {
                                if (menu.submenus) {
                                    menu.submenus.forEach(submenu => {
                                        userData.availableMenus.push(submenu.name)
                                    });
                                }
                                else
                                {
                                    userData.availableMenus.push(menu.name);
                                }
                            })
                        })
                        userData.availableMenus.push('changePassword')
                        userData.availableMenus.push('access')
                        userData.availableMenus.push('notFound')
                        userData.availableMenus.push('error')

                        context.commit('startUserSession', userData)
                        resolve(userData)
                    }).catch(function (error) {
                        // Vue.$log.info('login error from store' + error)
                        reject(new Error('Invalid username and password' + error))
                    })
                }
            })
        },
        logout(context) {
            context.commit("endUserSession");
        }
    },
    getters: {
        username: (state) => {
            return state.sessionActive ? state.username : 'test username'
        },
        role: (state) => {
            return state.sessionActive ? state.role : ''
        },
        menus: (state) => {
            return state.sessionActive ? state.menus : ''
        },
        sessionActive: (state) => state.sessionActive,
        availableMenus: (state) => {
            return state.sessionActive ? state.availableMenus : ''
        },
        accessToken: (state) => {
            return state.sessionActive ? state.userToken : ''
        },
        firstAvailableMenu: (state) => {
            return state.availableMenus.length > 0 ? state.availableMenus[0] : ''
        }
    }
}
