export default {
    namespaced: true,
    state: {
        filters: []
    },
    mutations: {
        setFilter(state, filterData) {
            var routeFilter = state.filters.find(item =>
                item.route == filterData.route
            );
            if (routeFilter != undefined) {
                routeFilter.data = filterData.filters;
                routeFilter.used = false;
            }
            else {
                var filter = {
                    route: filterData.route,
                    data: filterData.filters,
                    used: false
                }
                state.filters.push(filter);
            }

        },
        clearFilter(state, route) {
            var routeFilterIndex = state.filters.findIndex(item =>
                item.route == route
            );
            state.filters.splice(routeFilterIndex, 1);
        },
        markAsUsed(state, route) {
            var routeFilter = state.filters.find(item =>
                item.route == route
            );
            if (routeFilter != undefined) {
                routeFilter.used = true;
            }
        },
        clearAllFilters(state) {
            state.filters = [];
        }
    },
    actions: {
        setFilter(context, filterData) {
            context.commit('setFilter', filterData)
        },
        clearAll(context) {
            context.commit('clearAllFilters')
        },
        clearFilter(context, route) {
            context.commit('clearFilter', route)
        },
        markAsUsed(context, route) {
            context.commit('markAsUsed', route);
        }
    },
    getters: {
        filter: (state) => (route) => {
            let filter = state.filters.find(item => {
                return item.route == route
            });
            return filter;
        }
    }
}
