<template>
    <div class="layout-inline-menu" :class="{ 'layout-inline-menu-active': active }">
        <a class="layout-inline-menu-action p-d-flex p-dir-row p-ai-center" :class="menuMode === 'horizontal' ? 'p-p-3 p-p-lg-1 p-py-3' : 'p-p-3'" :style="{ 'min-width': menuMode === 'horizontal' ? '165px' : 0 }" @click="onClick($event)">
            <i class="pi pi-user p-mr-2"></i>
            <span class="p-d-flex p-flex-column p-text-center" :class="{ 'p-ml-2': !isRTL, 'p-mr-2': isRTL }">
                <span class="p-text-bold">{{ username }}</span>
            </span>
            <i class="layout-inline-menu-icon pi pi-angle-down" :class="{ 'p-ml-auto': !isRTL, 'p-mr-auto': isRTL }"></i>
        </a>
        <span class="layout-inline-menu-tooltip p-tooltip">
            <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
            <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ username }}</span>
        </span>

        <transition :name="menuMode === 'horizontal' && !isMobile() ? 'layout-inline-menu-action-panel-horizontal' : 'layout-inline-menu-action-panel'">
            <ul v-show="active" :class="['layout-inline-menu-action-panel', { 'layout-inline-menu-action-panel-horizontal': menuMode === 'horizontal' && !isMobile() }]">
                <li class="layout-inline-menu-action-item" @click="changePassword()">
                    <a class="p-d-flex p-flex-row p-ai-center">
                        <i class="pi pi-cog pi-fw"></i>
                        <span>{{ $t('menu.changePassword') }}</span>
                    </a>
                    <span class="layout-inline-menu-tooltip p-tooltip">
                        <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
                        <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ $t('menu.changePassword') }}</span>
                    </span>
                </li>
                <li class="layout-inline-menu-action-item" @click="logout()">
                    <a class="p-d-flex p-flex-row p-ai-center">
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>{{ $t('menu.logout') }}</span>
                    </a>
                    <span class="layout-inline-menu-tooltip p-tooltip">
                        <span class="layout-inline-menu-tooltip-arrow p-tooltip-arrow"></span>
                        <span class="layout-inline-menu-tooltip-text p-tooltip-text">{{ $t('menu.logout') }}</span>
                    </span>
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
import { store } from './store/index';
export default {
    name: 'AppInlineMenu',
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        menuMode: String,
        inlineMenuKey: String,
    },
    methods: {
        onClick(event) {
            this.$emit('change-inlinemenu', !this.active, this.inlineMenuKey);
            event.preventDefault();
        },
        isMobile() {
            return window.innerWidth <= 991;
        },
        logout: function () {
            this.$store.dispatch('user/logout', this.user);
            this.$router.push({ name: 'login' });
        },
        changePassword: function () {
            this.$router.push({ name: 'changePassword' });
        },
    },
    computed: {
        isRTL() {
            return this.$appState.RTL;
        },
        username() {
            return store.getters['user/username'];
        },
        role() {
            return store.getters['user/role'];
        },
    },
};
</script>

<style scoped>
</style>
