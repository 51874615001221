

import {createStore} from 'vuex'
import userModule from './modules/user'
import filtersModule from './modules/filters'
import createPersistedState from 'vuex-persistedstate'

export const store = createStore({
    strict: true, // process.env.NODE_ENV !== 'production',
    modules: {
        user: userModule,
        filters: filtersModule
    },
    state: {},
    mutations: {},
    plugins: [createPersistedState()]
})






